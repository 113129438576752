import { useMutation } from "@apollo/react-hooks";
import { notification, Spin } from "antd";
import idx from "idx";
import CREATE_ROLE from "./createRole.graphql";
import { CreateRole, CreateRoleVariables } from "./types/CreateRole";
import Authorize from "components/authorize";
import MainLayout from "components/layouts/main";
import RoleForm from "components/roleForm";
import React, { FC, useCallback } from "react";
import routes from "routes";
import { CreateRoleDto } from "types/graphql-global-types";
import { useErrorHandler, useNavigate } from "utils";

const RoleCreatePage: FC = () => {
  const [createRole, { error, loading }] = useMutation<
    CreateRole,
    CreateRoleVariables
  >(CREATE_ROLE, { refetchQueries: ["GetRoleList"] });

  useErrorHandler(error);

  const navigateToRoles = useNavigate(routes.roles);

  const handleSubmit = useCallback(
    async (input: CreateRoleDto) => {
      const { data, errors } = await createRole({
        variables: { input },
      });

      if (!errors) {
        notification.success({
          message: "Успех!",
          description: (
            <>
              Роль{" "}
              <strong>{idx(data, x => x.srv.role.create.displayName)}</strong>{" "}
              была успешно создана.
            </>
          ),
        });
      }

      navigateToRoles();
    },
    [createRole, navigateToRoles]
  );

  return (
    <Authorize>
      <MainLayout title="Новая роль" onBack={navigateToRoles}>
        <Spin spinning={loading}>
          <RoleForm onSubmit={handleSubmit} />
        </Spin>
      </MainLayout>
    </Authorize>
  );
};

export default RoleCreatePage;
